<template>
   <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
        <h3>Edit Microservice</h3>
      </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text class="px-6">
         <!-- EXPANSION Basic Info  PANEL -->
      <v-col cols="12" class="panelBorder pa-0">
        <v-expansion-panels v-model="basicPanel" multiple>
          <v-expansion-panel >
            <v-expansion-panel-header>
              <h3 class="pl-0 secondary--text text--darken-4">Microservice Details</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pl-5">
             
                                                        <v-row>
                                                            <v-col cols="12" sm="6">
                                                                <label>Microservice Name</label>
                                                                <v-text-field class="mt-2" outlined></v-text-field>
                                                            </v-col>
                                                            <v-col class="pl-8" cols="12" sm="6">
                                                                <label class="ml-1">Deployment Type</label>
                                                                <v-radio-group v-model="row" row class="mt-0">
                                                                    <v-radio label="Option 1" value="radio-1"></v-radio>
                                                                    <v-radio label="Option 2" value="radio-2"></v-radio>
                                                                </v-radio-group>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <label>Microservice Description</label>
                                                                <mavon-editor class="mt-2" language="en" outlined
                                                                    name="input-7-1" rows="3" />
                                                            </v-col>

                                                        </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" class="panelBorder pa-0">
        <v-expansion-panels v-model="basicPanel" multiple>
          <v-expansion-panel >
            <v-expansion-panel-header>
              <h3 class="pl-0 secondary--text text--darken-4">API Details</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-row>
                                                            <v-col cols="12" class="secondary lighten-3">
                                                                <div class="d-flex align-center">
                                                                    <v-row class="text-fields-row px-4">
                                                                        <v-col cols="12" sm="3">
                                                                            <label>Teamspace Code</label>
                                                                            <v-text-field outlined></v-text-field>
                                                                        </v-col>

                                                                        <v-col cols="12" sm="3">
                                                                            <label>Microservice Code</label>
                                                                            <v-text-field outlined></v-text-field>
                                                                        </v-col>

                                                                        <v-col class="" cols="12" sm="6">
                                                                            <label class="ml-1">Result Type</label>
                                                                            <v-radio-group v-model="row" row
                                                                                class="mt-0">
                                                                                <v-radio label="getresults"
                                                                                    value="radio-1"></v-radio>
                                                                                <v-radio label="getpredictions"
                                                                                    value="radio-2"></v-radio>
                                                                            </v-radio-group>
                                                                        </v-col>
                                                                        <v-col cols="12" sm="3">
                                                                            <label>Major Version</label>
                                                                            <v-text-field outlined></v-text-field>
                                                                        </v-col>
                                                                        <v-col cols="12" sm="3">
                                                                            <label>minor Version</label>
                                                                            <v-text-field outlined></v-text-field>
                                                                        </v-col>
                                                                        <v-col cols="12" sm="4">
                                                                            <label>Patch Version</label>
                                                                            <div class="patchInput d-flex">
                                                                                <v-text-field outlined></v-text-field>
                                                                                <a href="http://" target="_blank"
                                                                                    rel="noopener noreferrer">
                                                                                    <v-icon
                                                                                        class="text-right ml-4">mdi-plus
                                                                                    </v-icon></a>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </v-col>

                                                                    </v-row>
                                                                </div>

                                                            </v-col>
                                                            <v-col cols="12" class="pl-0">
                                                                <label for="">End Point: <a
                                                                        style="color:#4B9ACC !important;text-decoration: underline;">https://apis.sentient.io/microservices/custommodels/tsdsofakenews/v1.1.1/getpredictions</a></label>
                                                                <v-icon style="color:#dd9e38;"
                                                                    class="text-right ml-4">mdi-square-edit-outline
                                                                </v-icon>
                                                                <v-icon style="color:#B22222;"
                                                                    class="text-right ml-4">mdi-trash-can-outline
                                                                </v-icon>
                                                            </v-col>

                                                        </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" class="panelBorder pa-0">
        <v-expansion-panels v-model="basicPanel" multiple>
          <v-expansion-panel >
            <v-expansion-panel-header>
              <h3 class="pl-0 secondary--text text--darken-4">Documentation Details</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>.
                <v-col cols="12" class="px-10">
                                                            <h3>Documentation Details</h3>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <label for="">Docs YAML File</label>
                                                            <div class="text-center d-flex-column justify-center fileBox"
                                                                style="width:20%;">
                                                                <v-icon class="secondary--text">
                                                                    mdi-cloud-upload</v-icon>
                                                                <div>UPLOAD</div>
                                                            </div>
                                                            <div>
                                                                <p class="mt-4" color="#9e9e9e"><v-icon
                                                                        class="secondary--text">
                                                                        mdi-download-outline</v-icon> Download sample
                                                                </p>
                                                            </div>
                                                        </v-col>
              <!-- <msbasicinfo :microAllData="microAllData" :validForm="validForm" :labels="labels" @microDataSubmitted="updateMicroAllData"></msbasicinfo> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      
      <div class="pl-10">
        <v-btn class="nocap" @click="closeMsEdit" text>
          <span class=""> Cancel</span>
        </v-btn>
        <v-btn class="nocap primary" text @click="msEditSave()">
          <span class="">SAVE</span>
        </v-btn>
      </div>
    </v-card-actions>
    </v-card>
</template>

<script>
export default {

}
</script>

<style>

</style>