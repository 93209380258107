<template>
   <v-card class="ms-title">
    <v-card-title class="text-center">
  
  <div class="py-2">
                  <h3 class="text-center"> Register Microservice
                  </h3>
                  <div class="closeicon-right">
                  <v-icon  @click.prevent="closeMsDetailDialog" class="text-right"
                    >mdi-close-circle-outline
                  </v-icon>
                  </div>
                </div>
</v-card-title>
<v-divider class="my-4"></v-divider>
<v-card-text>
      <div class="px-10 msForm">
        <!-- -----------Ms Details ------------>
        <v-col cols="12" class="px-0 py-6">
          <h2>Microservice Details</h2>
        </v-col>
        <v-row
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="6">
            <label class="my-4">Microservice Name</label>
            <div> Test to speech</div>
          </v-col>
          <v-col cols="6">
            <label class="my-4">Deployment Type</label>
            <div>New</div>
          </v-col>
          <v-col cols="12">
            <label class="my-4">Microservice Description</label>
            <div>New</div>
          </v-col>
          <v-col cols="12">
            <h4 class="my-4">Recource Details</h4>
            
          </v-col>
          <v-col cols="4">
            <label class="my-4">vCPU</label>
            <div>4</div>
          </v-col>
          <v-col cols="4">
            <label class="my-4">Memory (GB)</label>
            <div>16</div>
          </v-col>
          <v-col cols="4">
            <label class="my-4">GPU (T4)</label>
            <div>No</div>
          </v-col>
          <v-col cols="4">
            <label class="my-4">Environment Variable</label>
            <div>-</div>
          </v-col>
          <v-col cols="4">
            <label class="my-4">Port</label>
            <div>5000</div>
          </v-col>
        </v-row>
<!-- API Details -->
        <v-col cols="12" class="px-0 py-6">
          <h2>API Details</h2>
        </v-col>
        <v-row
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
        <v-col cols="12">
            <label class="my-4">End Point</label>
            <div>https://apis.sentient.io/microservices/custommodels/tsdsofakenews/v1.1.1/getpredictions</div>
          </v-col>
        </v-row>
        <!-- Documentation Details -->
        <v-col cols="12" class="px-0 py-6">
          <h2>Documentation Details</h2>
        </v-col>
        <v-row
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
        <v-col cols="12">
            <label class="my-4">Docs YAML File</label>
            <div>https://apis.sentient.io/microservices/custommodels/tsdsofakenews/v1.1.1/getpredictions</div>
          </v-col>
        </v-row>
      </div>
      </v-card-text>
      <v-divider></v-divider>
    <v-card-actions >
      <div class="py-4">
      <v-btn class="nocap" @click.prevent="closeMsDetailDialog" text>
        <span class="">Cancel</span>
      </v-btn>
      <v-btn class="nocap primary" text @click="msEditDialog()">
        <span class="">
          <v-icon>mdi-square-edit-outline</v-icon>
          Edit
        </span>
      </v-btn>
      </div>
    </v-card-actions>   
</v-card>
</template>

<script>
export default {

}
</script>

<style>

</style>