<template>
  <div>
    <!-- <navigation/> -->
    <div>
      <v-breadcrumbs :items="items" v-if="ownerType === 'PM'">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <div class="owner-tlt">
        <headline :selectedOwner="selectedOwner" :ownerDesc="ownerDesc" />
      </div>
      <v-content class="white maincontent">
        <div class="pb-4">
          <div class="d-flex justify-space-between align-center">
            <!-- <div cols="12 px-0" v-if="ownerType === 'PM'">
              <v-btn text class="nocap px-0" @click="$router.go(-1)">
                <span class="grey--text">
                  <v-icon style="margin-left: -10px">mdi-arrow-left</v-icon>
                  {{ labels.BK }}
                </span>
              </v-btn>
            </div> -->
            <div>
              <h2 class="text-center font-weigt-bold">{{ labels.PROC }}</h2>
            </div>
            <div class="text-right">
              <v-spacer></v-spacer>
              <!-- <v-btn
                class="tertiary2 white--text nocap py-5"
                @click.stop="addPromocodes"
              >
                <v-icon class="pr-1 add">mdi-plus-circle</v-icon>
                Add Promocodes
              </v-btn> -->
            </div>
          </div>
          <div class="d-flex flex-md-row flex-sm-column justify-start align-md-end justify-md-space-between">
        <div> 
          <v-chip outlined
            @click="makeSelectedEmpty"
            class="ma-2 white"
          v-if="selectedPromo.length"
          >
            <v-icon>mdi-close-circle</v-icon><span>&nbsp;</span>  {{ selectedPromo.length }} selected
          </v-chip>
        </div>
          <!----------Searching--------------------- -->

          <!-- <div>
          <div v-show="ownerType === 'PM' && false">
            <label class="secondary--text text--lighten-1">{{ labels.EDI_PROC }}</label>
             <v-combobox  class="mt-4 selectCom"
              v-model="selectedOwner"
              :items="ownerList"
              item-text="ownerName"
              item-value="ownerId"
              :search-input.sync="search"
              label="select owner"
              hide-selected
              @change="changeOwner"
              dens
              solo
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ search }}</strong
                      >". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </div>
          </div> -->
          <!-- ---------------------------- -->
          <div>
            <v-btn text
                class="tertiary2--text nocap py-5"
                @click.stop="activePromoList = !activePromoList"
              >
                 <!-- <v-icon class="pr-1 add" style="font-size:26px">mdi-plus-network-outline </v-icon> -->
                   
                <div v-if="activePromoList" @click.prevent="inactivePromocodes"><v-icon class="pr-1 add" style="font-size:26px"> mdi-view-list-outline </v-icon>Inactive Promocodes</div>
                <div v-else @click.prevent="activePromocodes"><v-icon class="pr-1 add" style="font-size:26px"> mdi-view-list-outline </v-icon>Active Promocodes</div>
              </v-btn>
            <v-btn text
                class="tertiary2--text nocap py-5"
                @click.stop="addPromocodes"
              >
                 <!-- <v-icon class="pr-1 add" style="font-size:26px">mdi-plus-network-outline </v-icon> -->
                   <v-icon class="pr-1 add" style="font-size:26px"> mdi-tag </v-icon>
                {{ labels.AD_PROC }}
              </v-btn>
            <v-btn text
              @click.stop="deletetPromo = true"
              :disabled="!selectedPromo.length"
              class="error--text nocap py-5"
            >
            <v-icon  class="pr-1 add" style="font-size:26px">mdi-trash-can  </v-icon>
               {{ labels.DEL }}
            </v-btn>
          </div>
          </div>
          <div>
            <v-data-table
             :mobile-breakpoint="0"
              v-model="selectedPromo"
              :headers="headers"
              :items="promocodeListing"
              item-key="code"
              show-select
              @click:row="openPromoDetail"
              class="elevation-1 listingTable"
            >
            </v-data-table>
          </div>
          <v-row v-if="addpromoDialog" justify="center" style="margin-bottom: 25px !important;">
            <v-form ref="form" v-model="valid">
              <v-dialog
                class="stepBystep"
                scrollable
                v-model="addpromoDialog"
                persistent
                max-width="1000px"
              >
                <v-card class="ms-title">
                  <v-card-title>
                    <div class="py-2">
                      <h3 class="text-center">{{ labels.AD_PROC }}</h3>
                    </div>
                  </v-card-title>
                  <v-divider class="my-4"></v-divider>
                  <v-card-text style="height: 400px">
                    <div>
                      <v-col cols="12" class="px-10">
                        <h3>Basic info</h3>
                      </v-col>
                      <div>
                        <addpromocode
                          :promoAllData="promoAllData"
                          :promoType="promoType"
                          :discountType="discountType"
                          :creditValidityType="creditValidityType"
                          :isValidForm="isValidForm"
                          :labels="labels"
                          @promoDataSubmitted="updatedPromoAllData"
                        ></addpromocode>
                      </div>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <div class="px-md-10 pt-3">
                    <v-alert
                      text
                      :type="response_status"
                      v-if="response_message.length"
                    >
                      {{ response_message }}
                    </v-alert>
                  </div>
                  <v-card-actions class="px-10 py-6 text-center">
                   
                    <v-btn
                      class="nocap"
                      large
                      text
                      @click="addpromoDialog = false"
                    >
                      {{ labels.CAN }}
                    </v-btn>
                    <v-btn
                      class="nocap mx-5"
                      large
                      color="primary"
                      @click="save"
                    >
                      {{ labels.SAV }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
          </v-row>
          <!-- Success pop up -->
          <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="successPromo"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-img
                    style="z-index: 1"
                    alt="sentient logo"
                    class="d-flex align-center justify-center"
                    src="@/assets/tick-mark1.svg"
                    contain
                    max-width="192"
                  ></v-img>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <!-- <v-icon color="primary" class="text-center" style="font-size:60px">mdi-check-circle </v-icon> -->

                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Success</div>
                  </v-card-title>
                  <p v-if="response_msg.length">{{ response_msg }}</p>
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap" color="primary" @click="successPromo = false">Done </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- End -->
          <!-- delete pop up -->
          <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="deletetPromo"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-avatar
                    color="error"
                    size="62"
                  >
                    <v-icon class="white--text text-h5">mdi-trash-can-outline</v-icon>
                  </v-avatar>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Delete Confirmation</div>
                  </v-card-title>
                  Are you sure want to delete the selected promocodes?
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap mx-5" text @click="deletetPromo = false">Cancel </v-btn>
                      <v-btn class="nocap mx-5" color="error" @click="deletePromo()">Delete </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- End -->
          <!-- -------++++ Promocode Detail Pages +++++--------->
          <v-dialog v-model="discountdetailDialog" width="1000" scrollable>
            <promodetail
              :promoAllData="promoAllData"
              :promoType="promoType"
              :discountType="discountType"
              :creditValidityType="creditValidityType"
              :labels="labels"
              @closeDetailDialog="closeDialog"
              @closePromoDetail="closePromoDetail"
            />
          </v-dialog>
          <!-- ----------------------------- -->
          <!-- -------++++ Edit Promocode +++++--------->
          <v-dialog
            v-model="promoEditDialog"
            width="1000"
            scrollable
            class="d-none"
          >
            <promoedit
              :promoAllData="promoAllData"
              :promoType="promoType"
              :discountType="discountType"
              :creditValidityType="creditValidityType"
              :response_message="response_message"
              :response_status="response_status"
              :labels="labels"
              @closePromoEdit="closePromoEdit"
              @promoEditedData="save"
            />
          </v-dialog>
          <!-- ----------------------------- -->
        </div>
      </v-content>
    </div>
    <loaderimg v-if="loader"/>
  </div>
</template> 
<script>
import loaderimg from "./loaderimg.vue";
import API_Util from "../services/util/API_Util.js";
import addpromocode from "./ownerpopup/addpromocode.vue";
import promoedit from "./promoedit.vue";
import promodetail from "./promolisting.vue";
import headline from "./headline.vue";
// import headline from "./headline.vue";

export default {
  components: {
    addpromocode,
    promodetail,
    promoedit,
    loaderimg,headline,
  },
  data: () => ({
    response_msg: "",
    ownerName: "",
    promodetailDialog: false,
    addpromoDialog: false,
    // discounteditDialog:false,
    promoEditDialog: false,
    discountedit: false,
    enableDelete: false,
    show1: false,
    singleSelect: false,
    deletetPromo: false,
    selected: [],
    promocodeListing: [],
    tempPromocodeListing: [],
    loading: false,
    search: "",
    selectedInt: [],
    selectedMs: [],
    items: [
      {
        text: "Owners",
        disabled: false,
        href: "/ownerlisting",
      },
      {
        text: "",
        disabled: false,
        href: "",
      },
      {
        text: "Marketing - Promo Codes",
        disabled: true,
        href: "",
      },
    ],
    apiKey: "",
    owner: "",
    org: "",
    user: "",
    promoAllData: {
      id: 0,
      type: "",
      code: "",
      start_date: "",
      end_date: "",
      credit: "",
      credit_validity_type: "",
      credit_validity_value: "",
      discount_type: "",
      discount_amount: "",
      minTopupAmount: "",
      description: "",
      dateUpdated: "",
    },
    response_message: "",
    response_status: "",
    selectedPromo: [],
    ownerList: [],
    selectedOwner: "",
    ownerDesc: "",
    discountdetailDialog: false,
    isValidForm: true,
    loader: true,
    successPromo: false,
    ownerType: "",
    labels: {},
    language: "EN",
    activePromoList: true,
  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.ownerName = sessionObj.session.ownerName;
    this.org = sessionObj.session.org;
    this.user = sessionObj.session.id;
    this.ownerType = sessionObj.session.ownerType;
    this.selectedOwner = localStorage.getItem("ownerName");
    this.ownerDesc = localStorage.getItem('ownerDesc');
    this.owner = localStorage.getItem("owner");
    this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
    this.items[1].href = "/ownersettings";
    this.getFlexi();
    this.getOrgList();
    this.getPromocodeList();
    this.getPromoType();
    this.getDiscountType();
    this.getCreditValidityType();
  },
  computed: {},

  watch: {
    selected(val) {
      this.enableDelete = false;
      if (val.length) {
        this.enableDelete = true;
      }
    },
  },

  methods: {
    addPromocodes() {
      this.addpromoDialog = true;
      this.clearPromoAllData();
    },
    changeOwner(obj) {
      this.loader = true;
      localStorage.setItem("owner", obj.ownerId);
      localStorage.setItem('ownerName', obj.ownerName);
      this.selectedOwner = obj.ownerName;
      this.ownerDesc = obj.desc;
      localStorage.setItem('ownerDesc', obj.desc);
      this.$emit('ownerchange',obj.ownerType);
      this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
      this.getPromocodeList();
      this.getPromoType();
      this.getDiscountType();
      this.getCreditValidityType();
    },
    makeSelectedEmpty() {
      this.selectedPromo = [];
    },
    async getOrgList() {
      var empt = {}
      await API_Util.URLS.axios
        .post(API_Util.URLS.listOrg, empt, {
          headers: { "x-api-key": this.apiKey },
        })
        .then((response) => {
          this.ownerList = response.data.results;
        });
    },
    cancelDelete(obj) {
      obj.value = false;
      this.selectedPromo = [];
    },
    closePromoEdit(emittedValue) {
      this.promoEditDialog = emittedValue;
      this.clearPromoAllData();
    },
    closePromoDetail(emittedValue) {
      this.discountdetailDialog = emittedValue;
      this.clearPromoAllData();
    },
    async getPromoType() {
      var data = {
        owner_id: localStorage.getItem("owner"),
        lang: "EN",
        code: "PROMO_POS_TYPE",
      };
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.flexyListValue, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
      this.promoType = response;
    },
    async getDiscountType() {
      var data = {
        owner_id: localStorage.getItem("owner"),
        lang: "EN",
        code: "PROMO_DISCOUNT_TYPE",
      };
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.flexyListValue, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
      this.discountType = response;
    },
    async getCreditValidityType() {
      var data = {
        owner_id: localStorage.getItem("owner"),
        lang: "EN",
        code: "PROMO_CREDIT_VALIDITY_TYPE",
      };
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.flexyListValue, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
      this.creditValidityType = response;
    },
    openPromoDetail(obj) {
      this.discountdetailDialog = true;
      this.promoAllData = obj;
    },
    async getPromocodeList() {
      var owner_id = {"owner_id": localStorage.getItem("owner")}
      let response = await API_Util.URLS.axios
        .post(
          API_Util.URLS.listPromocode, owner_id,
          {
            headers: { "x-api-key": this.apiKey },
            params: {},
          }
        )
        .then((response) => response.data)
        .catch((err) => console.log(err));
        this.tempPromocodeListing = response.data;
        this.activePromocodes();
      this.loader = false;
    },
    activePromocodes() {
      this.promocodeListing = [];
      this.promocodeListing = this.tempPromocodeListing.filter((obj)=>{
          return obj.status == 'Active'
      });
    },
    inactivePromocodes() {
      this.promocodeListing = [];
      this.promocodeListing = this.tempPromocodeListing.filter((obj) => {
        return obj.status == 'Inactive'
      });
      this.loader = false;
    }, 
    updatedPromoAllData(updatedPromo) {
      this.promoAllData = updatedPromo;
    },
    clearPromoAllData() {
      this.promoAllData = {
        id: 0,
        org: this.org,
        type: "",
        code: "",
        start_date: "",
        end_date: "",
        credit: "",
        credit_validity_type: "",
        credit_validity_value: "",
        discount_type: "",
        discount_amount: "",
        minTopupAmount: "",
        description: "",
        dateUpdated: "",
        updatedBy: this.user,
        owner_id: this.owner,
      };
      this.response_message = "";
      this.response_status = "";
      this.isValidForm = true;
      this.activePromoList = true;
    },
    async save() {
      if (this.promoAllData.type == "") {
        this.isValidForm = false;
      } else if (this.promoAllData.code == "") {
        this.isValidForm = false;
      } else if (this.promoAllData.description == "") {
        this.isValidForm = false;
      } else if (this.promoAllData.type=='PROMO' && this.promoAllData.credit == 0) {
        this.isValidForm = false;
      } else if (this.promoAllData.type=='PROMO' && this.promoAllData.credit_validity_type == "") {
        this.isValidForm = false;
      } else if (this.promoAllData.type=='PROMO' && this.promoAllData.credit_validity_value == "") {
        this.isValidForm = false;
      } else if (this.promoAllData.type=='POS' && this.promoAllData.discount_type == "") {
        this.isValidForm = false;
      } else if (this.promoAllData.type=='POS' && this.promoAllData.minTopupAmount == "") {
        this.isValidForm = false;
      } else if (this.promoAllData.type=='POS' && this.promoAllData.discount_amount == "") {
        this.isValidForm = false;
      } else if ((this.promoAllData.start_date > this.promoAllData.end_date)) {
        this.isValidForm = false;
      } else if (this.promoAllData.credit_validity_type === 'month' && !this.promoAllData.credit_validity_value){
        this.isValidForm = false;
      } else if (this.promoAllData.credit_validity_type === 'date' && (this.promoAllData.start_date > this.promoAllData.credit_validity_value)) {
        this.isValidForm = false;
      }
      else {
        this.isValidForm = true;
      this.promoAllData["org_id"] = this.org;
      this.promoAllData["updatedBy"] = this.user;
      this.promoAllData["owner_id"] = localStorage.getItem("owner");
      this.response_message = "";
      this.response_status = "";
      if (this.promoAllData.start_date == "") {
        this.promoAllData.start_date = this.getCurrentDate();
      }
      if (this.promoAllData.end_date == "") {
        this.promoAllData.end_date = this.getCurrentDate();
      }
      await API_Util.URLS.axios
        .post(API_Util.URLS.addPromocode, this.promoAllData, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => {
          if (response.data.status === "Failure") {
            this.response_message = response.data.message;
            this.response_status = "error";
          } else {
            if(this.promoAllData.id > 0){
              this.response_msg = "Successfully Updated";
              this.successPromo = true;
            }
            else{
              this.response_msg = "Successfully Created";
              this.successPromo = true;
            }
            setTimeout(() => {
              this.getPromocodeList();
              this.addpromoDialog = false;
              if (this.promoAllData.id > 0) {
                this.promoEditDialog = false;
              }
              this.clearPromoAllData();
            }, 2000);
          }
        })
        .catch((err) => console.log(err));
      }
    },
    getCurrentDate() {
      var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
    },
    allOwners() {
      this.$router.push("/ownerlisting", () => { window.location.reload()});
    },
    closeDialog(value) {
      this.discountedit = value;
      this.promoEditDialog = true;
      this.discountdetailDialog = false;
    },
    openFun(type) {
      this.$router.push({ name: "popup", params: { type: type } });
    },
    nextStep() {
      if (this.currentStep < 7) {
        this.currentStep = this.currentStep + 1;
      }
    },
    backStep() {
      if (this.currentStep > 1) {
        this.currentStep = this.currentStep - 1;
      }
    },
    next() {
      this.loading = true;

      setTimeout(() => {
        this.search = "";
        this.selectedInt = [];
        this.selectedMs = [];
        this.loading = false;
      }, 2000);
    },
    deletePromo() {
      let toDeleteIds = {};
      this.selectedPromo.forEach(function (arrayItem) {
        toDeleteIds["ids"] = arrayItem.id;
      });
      API_Util.URLS.axios
        .post(API_Util.URLS.deletePromocode, toDeleteIds, {
          headers: { "x-api-key": this.apiKey },
          params: {},
        })
        .then((response) => response)
        .catch((err) => console.log(err));
      toDeleteIds = [];
      this.selectedPromo = [];
      this.deletetPromo = false;
      this.clearPromoAllData();
      setTimeout(() => {
        this.getPromocodeList();
      },500); 
    },
    getFlexi() {
      API_Util.URLS.axios
        .post(
          API_Util.URLS.getlblbyscr,
          {
            owner_id: localStorage.getItem("owner"),
            scrId: "PROMO",
            lang: this.language,
          },
          { headers: { "x-api-key": this.apiKey } }
        )
        .then((response) => {
          this.labels = response.data.labels;
          this.headers = [
                          { text: this.labels.NM, value: "code" },
                          { text: this.labels.TY, value: "type" },
                          { text: this.labels.DESC, value: "description" },
                          { text: this.labels.CREAT_DT, value: "dateUpdated" },
                           { text: 'STATUS', value: "status" },
                        ];
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>
<style lang="scss">
.v-input__append-inner {
  margin-top: 11px !important;
}
.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 46px !important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
.container {
  max-width: 1224px;
}
.v-menu__content {
  background-color: white;
}
.v-chip .mdi-pencil-box-multiple {
  font-size: 21px !important;
}
.v-btn::before {
  background-color: transparent !important;
}
.v-stepper__step__step {
  height: 12px !important;
  min-width: 12px !important;
  width: 12px !important;
}

.v-stepper {
  // .v-stepper__step__step.primary {
  //     background-color: red !important;
  //     border-color: red !important;
  // }
  .v-divider {
    border-width: thin 0px 3px 0 !important;
  }

  .v-stepper__step {
    padding: 30px 0px 0px;

    hr {
      .v-stepper--alt-labels.v-stepper__header.v-divider {
        margin: 35px -80px 0 !important;
      }
    }
  }
}

//  .v-stepper__step--complete + .v-divider{
//      background-color: red !important;
//   }
.v-stepper__step__step {
  color: transparent !important;
  .v-icon {
    display: none !important;
  }
}
.v-stepper__header {
  box-shadow: none !important;
}

.ms-title {
  .v-card__title {
    text-align: center !important;
    display: block !important;
  }
}
.v-card__actions {
  text-align: center !important;
  display: block !important;
}
</style>
