<template>
  <div>
    <v-breadcrumbs :items="items">
      <template v-slot:divider>
        <v-icon>mdi mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <div class="d-flex flex-column flex-md-row justify-space-between font-weigt-bold">
<div>
    <div class="display-1">{{ selectedOwner }}</div>
    <div class="subtitle">{{ ownerDesc }}</div>
</div>
<div>
<div class="d-flex justify-start align-center ">
  <label class="mr-3">Select Owner :</label>
    <v-combobox
                class="mt-4 selectCom"
                v-model="selectedOwner"
                :items="ownerList"
                item-text="ownerName"
                item-value="ownerId"
                :search-input.sync="search"
                label="Select owner"
                hide-selected
                @change="changeOwner"
                dens
                solo
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching "<strong>{{ search }}</strong
                        >". Press <kbd>enter</kbd> to create a new one
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <!-- <template v-slot:item>
                <div class="test-class">
            <div>{ownerList}</div>
              
                </div>
                </template> -->
              </v-combobox>
              
</div>
</div>
    </div>
  </div>
</template>
<script>
import API_Util from "../services/util/API_Util.js";
export default {
    name: "headline",
    props: ['selectedOwner', 'ownerDesc'],
    data() {
      return {
        ownerList: [],
        apiKey: "",
      }
    },
    created() {
      var sessionObj = this.$session.get("user_session");
      this.apiKey = sessionObj.session.userApiKey;
      this.getOrgList();
    },
    methods: {
      async getOrgList() {
        var empt = {}
          await API_Util.URLS.axios
          .post(API_Util.URLS.listOrg, empt, {
            headers: { "x-api-key": this.apiKey },
          })
          .then((response) => {
            this.ownerList = response.data.results;
          });
      },
      changeOwner(obj) {
        this.loader = true;
        location.reload();
        localStorage.setItem("owner", obj.ownerId);
        localStorage.setItem("ownerName", obj.ownerName);
        localStorage.setItem("ownerType", obj.ownerType);
        localStorage.setItem("redisCloud", obj.redisCloud);
        this.selectedOwner = obj.ownerName;
        this.ownerDesc = obj.desc;
        localStorage.setItem("ownerDesc", obj.desc);
        this.$emit("ownerchange", obj.ownerType);
        this.items[1].text =
          "Sentient.io (" + localStorage.getItem("ownerName") + ")";
        // this.getMicroserviceList();
        // this.getFunctionSource();
    },
    }
};
</script>
<style lang="scss">
.test-class{
  color:red !important
}

</style>