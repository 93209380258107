<template>
    <div>
        <!-- <navigation/> -->
        <div>
            <v-breadcrumbs :items="items" v-if="ownerDrop">
                <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                </template>
            </v-breadcrumbs>
            <div class="owner-tlt">
                <headline :selectedOwner="selectedOwner" :ownerDesc="ownerDesc" v-if="ownerDrop" />
            </div>
            <v-content class="white maincontent">
                <div class="pb-4">
                    <div class="d-flex justify-space-between align-center">
                        <div>
                            <h2 class="text-center font-weigt-bold">Microservice{{ labels.HEADING }}</h2>
                        </div>
                        <div class="text-right">
                            <v-spacer></v-spacer>
                        </div>
                    </div>
                    <div class="
                        d-flex
                        flex-md-row flex-sm-column
                        justify-start
                        align-md-end
                        justify-md-space-between
                        mb-2">
                        <div>
                            <v-chip outlined @click="makeSelectedEmpty" class="ma-2 white" v-if="selectedLabel.length">
                                <v-icon>mdi-close-circle</v-icon><span>&nbsp;</span> {{ selectedLabel.length }} selected
                            </v-chip>
                        </div>
                        <div>
                            <v-btn text class="tertiary2--text nocap py-5" @click.stop="openpopup()">
                                <v-icon class="pr-1 add" style="font-size: 26px">mdi-view-dashboard-edit-outline
                                </v-icon>
                                Register Microservice
                                <!-- {{ labels.AD_FL_LABE }} -->
                            </v-btn>
                            <v-btn text @click.stop="deleteLabel = true" :disabled="!selectedLabel.length"
                                class="error--text nocap py-5">
                                <v-icon class="pr-1 add" style="font-size: 26px">mdi-trash-can
                                </v-icon>
                                {{ labels.DEL }}
                            </v-btn>
                        </div>
                    </div>
                    <div>
                        <v-data-table
                         :mobile-breakpoint="0"
                         :headers="header" 
                         :search="search_label"
                         :items="flexyLabelData" item-key="id" show-select
                        @click:row="openMsDetailPage"
                            class="elevation-1 listingTable">
                            <template v-slot:item="{item}">
              <tr>
                <td>
                    <v-checkbox
                      v-model="selectedMicro"
                      :value="item"
                      style="margin: 0px; padding: 0px"
                      hide-details
                      multiple
                      color="dark-grey"
                    />
                </td>
                <td @click="openMsDetailPage(item)">Arunkumar</td>
                <td @click="openMsDetailPage(item)">{{item.code}}</td>
                <td @click="openMsDetailPage(item)" v-if="item.micro_type==='ASSIGN'">Assigned</td>
                <td @click="openMsDetailPage(item)" v-else-if="item.micro_type==='DUP'">Duplicated</td>
                <td @click="openMsDetailPage(item)" v-else>Owned</td>
                <td @click="openMsDetailPage(item)">{{item.access}}</td>
                <td @click="openMsDetailPage(item)">{{item.function_description}}</td>
                <td @click="openMsDetailPage(item)">{{item.created_by}}</td>
                <td @click="openMsDetailPage(item)">{{item.date_updated}}</td>
              </tr>
            </template>
                        </v-data-table>
                    </div>
                    <!-- -----------Register MicroService--------------------- -->
                    <v-row justify="center">
                        <v-form>
                            <v-dialog class="stepBystep" scrollable v-model="regMicro" persistent max-width="1000px">
                                <v-card class="ms-title">
                                    <v-card-title>
                                        <div class="py-2">
                                            <h3 class="text-center">Register Microservice</h3>
                                            <div class="closeicon-right">
                                                <v-icon @click="clearObjectData"
                                                    class="text-right">mdi-close-circle-outline
                                                </v-icon>
                                            </div>
                                        </div>
                                    </v-card-title>
                                    <v-divider class="my-4"></v-divider>
                                    <v-card-text style="height: 400px" class="basicIfo-sec">
                                        <div>
                                            <v-stepper alt-labels v-model="currentStep" flat class="stepper sticky">
                                                <v-stepper-header flat>
                                                    <template v-for="(item, index) in stepperData">
                                                        <v-stepper-step :key="item.id"
                                                            :complete="currentStep > index + 1" :step="index + 1">
                                                            <span class="caption"> {{ item.title }}</span>
                                                        </v-stepper-step>
                                                        <v-divider :key="item.id"></v-divider>
                                                        <!-- <v-divider
                                                            :key="item.id"
                                                            v-if="(microAllData.clas === '' || microAllData.clas === 'CLASS_DATA') && index < 3"
                                                            ></v-divider>
                                                            <v-divider
                                                            :key="item.id"
                                                            v-else-if="microAllData.clas != 'CLASS_DATA' && index < 3"
                                                            ></v-divider> -->
                                                    </template>
                                                </v-stepper-header>

                                                <v-stepper-items>
                                                    <v-stepper-content step="1">
                                                        <v-col cols="12" class="basicInfo-tlt">
                                                            <h3>Microservice Details</h3>
                                                        </v-col>
                                                        <v-row>
                                                            <v-col cols="12" sm="6">
                                                                <label>Microservice Name</label>
                                                                <v-text-field class="mt-2" outlined></v-text-field>
                                                            </v-col>
                                                            <v-col class="pl-8" cols="12" sm="6">
                                                                <label class="ml-1">Deployment Type</label>
                                                                <v-radio-group v-model="row" row class="mt-0">
                                                                    <v-radio label="Option 1" value="radio-1"></v-radio>
                                                                    <v-radio label="Option 2" value="radio-2"></v-radio>
                                                                </v-radio-group>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <label>Microservice Description</label>
                                                                <mavon-editor class="mt-2" language="en" outlined
                                                                    name="input-7-1" rows="3" />
                                                            </v-col>

                                                        </v-row>

                                                        <!-- <msbasicinfo
                                                            :microAllData="microAllData"
                                                            :validForm="validForm"
                                                            :labels="labels"
                                                            @microDataSubmitted="updateMicroAllData"
                                                            @changeStepper="changeStepper"
                                                            /> -->
                                                    </v-stepper-content>

                                                    <v-stepper-content step="2">
                                                        <v-col cols="12" class="basicInfo-tlt">
                                                            <h3>API Details</h3>
                                                        </v-col>

                                                        <v-row>
                                                            <v-col cols="12" class="secondary lighten-3">
                                                                <div class="d-flex align-center">
                                                                    <v-row class="text-fields-row px-4">
                                                                        <v-col cols="12" sm="3">
                                                                            <label>Teamspace Code</label>
                                                                            <v-text-field outlined></v-text-field>
                                                                        </v-col>

                                                                        <v-col cols="12" sm="3">
                                                                            <label>Microservice Code</label>
                                                                            <v-text-field outlined></v-text-field>
                                                                        </v-col>

                                                                        <v-col class="" cols="12" sm="6">
                                                                            <label class="ml-1">Result Type</label>
                                                                            <v-radio-group v-model="row" row
                                                                                class="mt-0">
                                                                                <v-radio label="getresults"
                                                                                    value="radio-1"></v-radio>
                                                                                <v-radio label="getpredictions"
                                                                                    value="radio-2"></v-radio>
                                                                            </v-radio-group>
                                                                        </v-col>
                                                                        <v-col cols="12" sm="3">
                                                                            <label>Major Version</label>
                                                                            <v-text-field outlined></v-text-field>
                                                                        </v-col>
                                                                        <v-col cols="12" sm="3">
                                                                            <label>minor Version</label>
                                                                            <v-text-field outlined></v-text-field>
                                                                        </v-col>
                                                                        <v-col cols="12" sm="3">
                                                                            <label>Patch Version</label>
                                                                            <div class="patchInput">
                                                                                <v-text-field outlined></v-text-field>
                                                                                <a href="http://" target="_blank"
                                                                                    rel="noopener noreferrer">
                                                                                    <v-icon
                                                                                        class="text-right">mdi-attachment
                                                                                    </v-icon></a>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </v-col>

                                                                    </v-row>
                                                                </div>

                                                            </v-col>
                                                            <v-col cols="12" class="pl-0">
                                                                <label for="">End Point: <a
                                                                        style="color:#4B9ACC !important;text-decoration: underline;">https://apis.sentient.io/microservices/custommodels/tsdsofakenews/v1.1.1/getpredictions</a></label>
                                                                <v-icon style="color:#dd9e38;"
                                                                    class="text-right ml-4">mdi-square-edit-outline
                                                                </v-icon>
                                                                <v-icon style="color:#B22222;"
                                                                    class="text-right ml-4">mdi-trash-can-outline
                                                                </v-icon>
                                                            </v-col>

                                                        </v-row>
                                                        <!-- <msDescription
                                                            :microAllData="microAllData"
                                                            :labels="labels"
                                                            @microDataSubmitted="updateMicroAllData"
                                                            /> -->
                                                    </v-stepper-content>

                                                    <v-stepper-content step="3">
                                                        <v-col cols="12" class="px-10">
                                                            <h3>Documentation Details</h3>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <label for="">Docs YAML File</label>
                                                            <div class="text-center d-flex-column justify-center fileBox"
                                                                style="width:20%;">
                                                                <v-icon class="secondary--text">
                                                                    mdi-cloud-upload</v-icon>
                                                                <div>UPLOAD</div>
                                                            </div>
                                                            <div>
                                                                <p class="mt-4" color="#9e9e9e"><v-icon
                                                                        class="secondary--text">
                                                                        mdi-download-outline</v-icon> Download sample
                                                                </p>
                                                            </div>
                                                        </v-col>
                                                        <!-- <mstype :microAllData="microAllData" :request_type="request_type" :request_version="request_version" :labels="labels" :validForm="validForm" @microDataSubmitted="updateMicroAllData" /> -->
                                                    </v-stepper-content>

                                                </v-stepper-items>
                                            </v-stepper>
                                        </div>
                                        <div class="px-md-16">
                                         <!-- <v-alert
                                        text
                                        :type="response_status"
                                        v-if="response_message.length"
                                        >
                                        {{ response_message }}
                                        </v-alert> -->
                                        </div>
                                    </v-card-text>
                                    <v-card-actions class="px-10 py-6 text-center">
                                        <v-btn class="nocap" large text @click="clearObjectData">
                                            Cancel
                                        </v-btn>
                                        <!-- <v-btn
                                            class="nocap mx-5"
                                            large
                                            text
                                            @click="backStep()"
                                            v-if="currentStep > 1"
                                            >
                                            {{ labels.BK }}
                                            </v-btn> -->
                                        <v-btn class="nocap mx-5" large color="primary" @click="nextStep()">
                                            Next
                                        </v-btn>


                                        <!-- <v-btn
                                        class="nocap mx-5"
                                        large
                                        v-if="microAllData.clas === 'CLASS_DATA' && currentStep < 8"
                                        color="primary"
                                        @click="nextStep()"
                                        >
                                        {{ labels.NXT }}
                                        </v-btn>
                                        <v-btn
                                        class="nocap mx-5"
                                        large
                                        v-if="microAllData.clas != 'CLASS_DATA' && currentStep < 7"
                                        color="primary"
                                        @click="nextStep()"
                                        >
                                        {{ labels.NXT }}
                                        </v-btn> -->
                                                            <!-- <v-btn
                                        class="nocap mx-5"
                                        large
                                        v-if="microAllData.clas === 'CLASS_DATA' && currentStep == 8"
                                        color="primary"
                                        @click="save()"
                                        >
                                        {{ labels.MIC_SAVE }}
                                        </v-btn>
                                        <v-btn
                                        class="nocap mx-5"
                                        large
                                        v-if="microAllData.clas != 'CLASS_DATA' && currentStep == 7"
                                        color="primary"
                                        @click="save()"
                                        >
                                        {{ labels.MIC_SAVE }}
                                        </v-btn> -->
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-form>
                    </v-row>
                          <!-- -------++++ REgister Microservice Detail Pages +++++--------->
          <v-dialog v-model="regmsDetailDialog" width="1000" scrollable>
            <RegisterMsDetailpage
                :microAllData="microAllData"
                :micro_source="micro_source"
                :micro_limits="micro_limits"
                :request_type="request_type"
                :request_version="request_version"
                :labels="labels"
                :micro_type="micro_type"
                @closeMsDetailDialog="closeMsDetailDialog"
                @closeDetailDialog="closeDialog"
            />
          </v-dialog>
             <!-- -------++++ Edit Microservice +++++--------->
             <v-dialog v-model="RegmsEdit" width="1000" scrollable class="">
                <RegisteMsEditpageVue
                :microAllData="microAllData"
                :micro_source="micro_source"
                :micro_limits="micro_limits"
                :request_type="request_type"
                :request_version="request_version"
                :response_message="response_message"
                :response_status="response_status"
                :validForm="validForm"
                :labels="labels"
                :micro_type="micro_type"
                @closeMsEdit="closeEdit"
                @msEditSave="save"
                />
            
          </v-dialog>
                    <!-- delete pop up -->
                    <v-row justify="center">
                        <v-dialog class="msPop" style="box-shadow: none; background-color: transparent"
                            v-model="deleteLabel" persistent max-width="388" content-class="vDialog">
                            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                                style="background-color: transparent">
                                <v-card-title class="text-h5 text-bold d-flex text-center pa-0"
                                    style="margin-bottom: -19px">
                                    <v-avatar color="error" size="62">
                                        <v-icon class="white--text text-h5">mdi-trash-can-outline</v-icon>
                                    </v-avatar>
                                </v-card-title>
                                <v-card-text class="text-center white rounded-lg" style="height: 200px">
                                    <v-card-title class="text-h5 text-bold d-flex justify-center">
                                        <div class="mt-4">Delete Confirmation</div>
                                    </v-card-title>
                                    Are you sure want to delete?
                                    <v-card-actions>
                                        <div class="text-center mt-4">
                                            <v-btn class="nocap mx-5" text @click="deleteLabel = false">Cancel </v-btn>
                                            <v-btn class="nocap mx-5" color="error" @click="deleteLabels()">Delete
                                            </v-btn>
                                        </div>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-row>
                    <!-- End -->

                </div>
            </v-content>
        </div>
        <loaderimg v-if="loader" />
    </div>
</template> 
<script>

import loaderimg from "./loaderimg.vue";
import API_Util from "../services/util/API_Util.js";
import headline from "./headline.vue";
import RegisterMsDetailpage from "./RegisterMsDetailpage.vue";
import RegisteMsEditpageVue from "./RegisteMsEditpage.vue";

export default {
    components: {
    loaderimg,
    headline,
    RegisterMsDetailpage,
    RegisteMsEditpageVue,

},
    name: "label",
    data: () => ({
        response_message: "",
        search_label: "",
        ownerList: [],
        owner: "",
        apiKey: "",
        selectedOwner: "",
        selectedLabel: [],
        newLabel: {
            description: "",
            id: 0,
            labelCode: "",
            languages: [],
            orderNumber: "",
            orgId: localStorage.getItem("orgId"),
            ownerId: localStorage.getItem("owner"),
            screenCode: "",
            screenName: "",
            text: "",
            dateUpdated: "",
            updatedBy: 0,
            effectAllOwners: false,
        },
        regmsDetailDialog: false,
        RegmsEdit: true,
        regMicro: false,
        checkbox: false,
        response_msg: "",
        response_status: "",
        loading: false,
        search: "",
        labelList: [],
        items: [
            {
                text: "Owners",
                disabled: false,
                href: "/ownerlisting",
            },
            {
                text: "",
                disabled: false,
                href: "",
            },
            {
                text: "Deployments - Register Microservice",
                disabled: true,
                href: "",
            },
        ],
        stepperData: [
            {
                title: "Microservice Details",
                id: 0
            },
            {
                title: "API Details",
                id: 1
            },
            {
                title: "Documentation Details",
                id: 2
            },

        ],
        headers: [
            {
                text: "NAME",
                align: "start",
                sortable: true,
                value: "name",
            },
            { text: "Type", value: "type" },
            { text: "Description", value: "dis" },
        ],
        loader: false,
        ownerType: "",
        labels: {},
        language: "EN",
        ownerDrop: "",
        ownerDesc: "",
        isSuperAdmin: localStorage.getItem('owner') == 1,
    }),

    created() {
        var sessionObj = this.$session.get("user_session");
        this.apiKey = sessionObj.session.userApiKey;
        this.ownerName = sessionObj.session.ownerName;
        this.ownerDrop = JSON.parse(localStorage.getItem('ownerDrop'));
        this.selectedOwner = localStorage.getItem("ownerName");
        this.owner = localStorage.getItem("owner");
        this.orgId = localStorage.getItem("orgId");
        this.ownerDesc = localStorage.getItem('ownerDesc');
        localStorage.setItem('orgId', this.owner);
        this.ownerType = localStorage.getItem("ownerType");
        this.items[1].text =
            "Sentient.io (" + localStorage.getItem("ownerName") + ")";
        this.items[1].href = "/ownersettings";
        this.getFlexi();
        //   this.openpopup();x 
    },

    computed: {},

    watch: {

    },

    methods: {

        changeOwner(obj) {
            this.loader = true;
            localStorage.setItem("owner", obj.ownerId);
            localStorage.setItem("ownerName", obj.ownerName);
            localStorage.setItem("orgId", obj.id);
            localStorage.setItem('ownerType', obj.ownerType);
            this.selectedOwner = obj.ownerName;
            this.ownerDesc = obj.desc;
            localStorage.setItem('ownerDesc', obj.desc);
            this.$emit('ownerchange', obj.ownerType);
            this.items[1].text =
                "Sentient.io (" + localStorage.getItem("ownerName") + ")";
            this.isSuperAdmin = localStorage.getItem('owner') == 1,
                this.getLangList();
            this.getFlexyLabelList();
        },
        openpopup() {
            this.regMicro = true;
        },
        clearObjectData() {
            this.regMicro = false;
        },
        closeMsDetailDialog(value) {
      this.regmsDetailDialog = value;
    },
    closeDialog(value) {
      this.regmsDetailDialog = value;
      this.msEdit = true;
    },
        async openMsDetailPage(item) {
      this.micro_type = item.micro_type;
      var details = {"fId": item.function_id};
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.infoMicroservice, details, {
          headers: { "x-api-key": this.apiKey },
          params: {},
        })
        .then((response) => response.data)
        .catch((err) => console.log(err));
      this.microAllData = response;

      if (this.micro_type === 'ASSIGN') {
          var det = {"function_id": item.function_id, "owner_id": localStorage.getItem('owner')}
          let accessResponse = await API_Util.URLS.axios
          .post(API_Util.URLS.getAssignedOrgFunctionAccess, det, {
            headers: { "x-api-key": this.apiKey },
            params: {},
          })
          .then((response) => response.data)
          .catch((err) => console.log(err));
        this.microAllData.accessText = accessResponse.data.assigned_access;
        this.microAllData.access = accessResponse.data.assigned_access;
      }
      this.regmsDetailDialog = true;
    },
        getFlexi() {
            API_Util.URLS.axios
                .post(
                    API_Util.URLS.getlblbyscr,
                    {
                        owner_id: localStorage.getItem("owner"),
                        scrId: "MIC_SET",
                        lang: this.language,
                    },
                    { headers: { "x-api-key": this.apiKey } }
                )
                .then((response) => {
                    this.labels = response.data.labels;
                    this.header = [
                        {
                            text: this.labels.NAME,
                            align: "start",
                            sortable: true,
                            value: "function_name",
                        },
                        { text: this.labels.TYPE, value: "micro_type" },
                        { text: this.labels.DESC, value: "function_description" },
                        { text: "STATUS", value: "access" },
                        { text: "END POINT", value: "endpoint" },
                        { text: "YAML", value: "yaml" },
                    ];
                })
                .catch((e) => {
                    this.errors.push(e);
                });
        },


        allOwners() {
            this.$router.push("/ownerlisting");
        },

    },
};
</script>
<style lang="scss" scoped>
::v-deep .v-divider:last-child {
    display: none;
}

::v-deep .v-stepper--alt-labels .v-stepper__header {
    margin: auto;
    width: 65%;
}

::v-deep .v-radio .v-label {
    color: #000 !important;
}

::v-deep .v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-right: 20px;
}

::v-deep .v-radio .v-input--selection-controls__input .v-icon {
    font-size: 20px;
}

.patchInput .v-input {
    display: inline-block;
}

.patchInput a {
    background: #D4D4D4;
    border-radius: 4px;
    padding: 5px;
    text-decoration: unset;
    margin-left: 24px;
}

.basicInfo-tlt {
    padding: 12px 10px 0;
}
</style>
  